<template>
  <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisible" :title="$t('workOrder.orderCancel')"
    width="500px" top="10vh" append-to-body>
    <el-form ref="form" :model="workOrder" class="form" :label-width="$l('120px', '80px')">
      <el-form-item :label="$t('workOrder.revocationReason')" prop="revokeReason" :rules="$rule.notNull">
        <el-input v-model="workOrder.revokeReason" type="textarea"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible = false">{{ $t("common.cancel") }}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="submit">{{ $t("common.save") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>


export default {
  data() {
    return {
      workOrder: {
        revokeReason: "",
        workOrderNo: "",
        handleState: "",
        id: "",
      },
      dialogVisible: false,
      submitLoading: false,
    };
  },
  methods: {
    open(row) {
      this.workOrder.id = row.id;
      this.workOrder.workOrderNo = row.workOrderNo;
      this.workOrder.handleState = row.handleState;
      this.dialogVisible = true;
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true;
          if (this.workOrder.handleState === 1) {
            this.$message.error(this.$t("workOrder.tip.stoped"));
            return;
          }
          this.$confirm(this.$t("workOrder.workOrderNo") + " " + this.workOrder.workOrderNo + " ，" + this.$t("workOrder.tip.want") + this.$t("workOrder.cancel") + this.$t("workOrder.tip.mark"), this.$t("common.tips"), {
            confirmButtonText: this.$t("common.confirm"),
            cancelButtonText: this.$t("common.cancel"),
            type: "warning",
          }).then(() => {
            this.$http.post("workOrders/cancel/" + this.workOrder.id + "/" + this.workOrder.revokeReason).then(res => {
              this.$emit("submit-success");
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$message.success(this.$t("common.tip.saveSuccess"));
            }).catch((error) => {
              this.submitLoading = false;
              this.$message.error(this.$t("workOrder.cancel") + this.$t("common.tip.operationFailure") + "，" + error.response.data.message);
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.el-message-box {
  padding-bottom: 5px;
  margin-top: -20vh;
}
</style>
